import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useHttp from '../../../hooks/use-http';
import { CHAT_LIST_URL } from '../../../lib/api';
import { chatActions } from '../../../store/chat/chat-slice';
import AsideWelcome from './AsideWelcome';
import ChatList from './chats/ChatList';
import NewChat from './new-chat/NewChat';
import Search from './Search';
import Setting from './Setting';
import classes from './Aside.module.css';
import Splash from '../../../pages/Splash';

const Aside = () => {
  const isNewChatActive = useSelector((state) => state.chat.isNewChatActive);
  const threads = useSelector((state) => state.chat.threads);
  const token = useSelector((state) => state.auth.userData.access);

  const [filteredThreads, setFilteredThreads] = useState(threads);

  const dispatch = useDispatch();

  const { sendRequest: fetchAllChats, isLoading, setIsLoading } = useHttp(true);

  const filterThreadsHandler = useCallback(
    (searchValue) => {
      if (searchValue) {
        setFilteredThreads(
          threads.filter((thread) =>
            thread.person.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setFilteredThreads(threads);
      }
    },
    [threads]
  );

  useEffect(() => {
    if (threads.length) {
      setFilteredThreads(threads);
    }
  }, [threads]);

  // console.log('ASIDE THREADS', threads);

  useEffect(() => {
    if (!threads.length) {
      fetchAllChats(
        {
          url: CHAT_LIST_URL,
          token,
        },
        (successData) => {
          if (successData && successData.length) {
            dispatch(chatActions.initThreads(successData));
          }
        },
        (errorData) => {
          console.error(errorData);
        }
      );
    } else {
      setIsLoading(false);
    }
  }, [threads]);

  return (
    <aside className={`${classes['aside']} d-flex flex-column h-100`}>
      {isNewChatActive && <NewChat />}
      {!isNewChatActive && (
        <>
          <Setting />
          {isLoading && <Splash loader />}
          {!isLoading && threads.length !== 0 && (
            <>
              <Search onSearch={filterThreadsHandler} />
              <ChatList chats={filteredThreads} />
            </>
          )}
          {!isLoading && threads.length === 0 && <AsideWelcome />}
        </>
      )}
    </aside>
  );
};

export default Aside;
