import { memo } from 'react';

const Icon = ({ name, className = '', onClick, style, ...props }) => {
  let iconContent = <i className={`fas fa-${name} ${className}`}></i>;

  if (onClick)
    return (
      <div
        className='d-inline'
        style={{ cursor: onClick ? 'pointer' : 'auto', ...style }}
        onClick={onClick}
        {...props}
      >
        {iconContent}
      </div>
    );
  return iconContent;
};

export default memo(Icon);
