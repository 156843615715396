import { DateTime } from 'luxon';

const truncate = (str, limit = 25) => {
  return str.length > limit ? `${str.substr(0, limit - 1)} ...` : str;
};

// const getReadableDateTime = (datetime) => {
//   const dt = DateTime.fromISO(datetime).toLocal();
//   const rel = dt.toRelative();
//   const cal = dt.toRelativeCalendar();
//   if (cal === 'today') return dt.toFormat('HH:mm');
//   return rel;
// };

const getReadableTime = (datetime) => {
  const dt = DateTime.fromISO(datetime).toLocal();
  return dt.toFormat('hh:mm a');
};

const getReadableDateTime = (dtString, formatToday = true) => {
  const luxonDT = DateTime.fromISO(dtString).toLocal();
  const jsDT = luxonDT.toJSDate();

  const today = new Date();
  const yesterday = new Date(today);
  const oneDayBeforeYesterday = new Date(yesterday);
  const twoDayBeforeYesterday = new Date(yesterday);
  const threeDayBeforeYesterday = new Date(yesterday);
  const fourDayBeforeYesterday = new Date(yesterday);
  const fiveDayBeforeYesterday = new Date(yesterday);

  yesterday.setDate(yesterday.getDate() - 1);
  oneDayBeforeYesterday.setDate(yesterday.getDate() - 1);
  twoDayBeforeYesterday.setDate(yesterday.getDate() - 2);
  threeDayBeforeYesterday.setDate(yesterday.getDate() - 3);
  fourDayBeforeYesterday.setDate(yesterday.getDate() - 4);
  fiveDayBeforeYesterday.setDate(yesterday.getDate() - 5);

  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  if (jsDT.toDateString() === today.toDateString()) {
    if (formatToday) return luxonDT.toFormat('hh:mm a');
    return 'Today';
  } else if (jsDT.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else if (jsDT.toDateString() === oneDayBeforeYesterday.toDateString()) {
    return days[jsDT.getDay()];
  } else if (jsDT.toDateString() === twoDayBeforeYesterday.toDateString()) {
    return days[jsDT.getDay()];
  } else if (jsDT.toDateString() === threeDayBeforeYesterday.toDateString()) {
    return days[jsDT.getDay()];
  } else if (jsDT.toDateString() === fourDayBeforeYesterday.toDateString()) {
    return days[jsDT.getDay()];
  } else if (jsDT.toDateString() === fiveDayBeforeYesterday.toDateString()) {
    return days[jsDT.getDay()];
  } else {
    return luxonDT.toFormat('dd/MM/yyyy');
  }
};

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const titleCase = (string) => {
  return string
    .split(' ')
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export {
  truncate,
  getReadableDateTime,
  getReadableTime,
  isValidHttpUrl,
  isObjectEmpty,
  titleCase,
};
