import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EmojiPicker from 'emoji-picker-react';
import WSContext from '../../../../context/ws-context';
import Icon from '../../../UI/Icon';

const ChatWindowInput = () => {
  const [message, setMessage] = useState('');
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const { sendMessage } = useContext(WSContext);
  const selectedThread = useSelector((state) => state.chat.selectedThread);

  const emojiClickedHandler = (_, { emoji }) => {
    setMessage((currMessage) => currMessage + emoji);
  };

  // Handler for mobile devices
  const inputClickedHandler = () => {
    if (window.innerWidth < 768) {
      if (isEmojiOpen) setIsEmojiOpen(false);
    }
  };

  const sendMessageHandler = (e) => {
    e.preventDefault();
    setIsEmojiOpen(false);
    if (message) {
      sendMessage({
        type: 'message',
        message,
        threadId: selectedThread.id,
        targetUserId: selectedThread.person.id,
      });
      setMessage('');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (message && selectedThread.id) {
        sendMessage({
          type: 'typing',
          threadId: selectedThread.id,
          targetUserId: selectedThread.person.id,
        });
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [message, selectedThread.id]);

  return (
    <>
      {isEmojiOpen && (
        <div>
          <EmojiPicker
            disableSearchBar
            preload={true}
            onEmojiClick={emojiClickedHandler}
            pickerStyle={{ width: '100%' }}
          />
        </div>
      )}
      <form
        onSubmit={sendMessageHandler}
        className='d-flex align-items-center border-top p-3'
      >
        <Icon
          name='paperclip'
          className={'text-primary pe-3'}
          onClick={() => toast.success('Coming Soon!')}
        />
        <Icon
          name='microphone'
          className={'text-primary pe-3'}
          onClick={() => toast.success('Coming Soon!')}
        />
        <input
          type='text'
          autoFocus
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onClick={inputClickedHandler}
          className='form-control border-0 bg-white border-start'
          placeholder='Type here'
        />
        <div className='d-flex align-items-center'>
          <Icon
            name={isEmojiOpen ? 'xmark' : 'smile'}
            className='text-primary me-3'
            onClick={() => setIsEmojiOpen((currState) => !currState)}
          />
          <button
            type='submit'
            disabled={message === ''}
            className='btn btn-mix text-white d-flex align-items-center px-4'
          >
            Send
            <Icon name='chevron-circle-right' className='ms-2' />
          </button>
        </div>
      </form>
    </>
  );
};

export default ChatWindowInput;
