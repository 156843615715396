import logo from '../../../images/logo.png';
// import Icon from '../../UI/Icon';

const Welcome = () => {
  return (
    <div className='w-100 bg-secondary'>
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ minHeight: '100vh' }}
      >
        <img src={logo} alt='Chat App' height={150} width={200} />
        <span className='d-block'>Simple. Secure.</span>
      </div>
      {/* <Icon
        name='circle-plus'
        className='fa-3x text-mix'
        style={{ position: 'absolute', bottom: 20, right: 20 }}
        onClick={() => {}}
      /> */}
    </div>
  );
};

export default Welcome;
