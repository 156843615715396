import { useSelector } from 'react-redux';
import Splash from './Splash';
import Dashboard from './Dashboard';
import { WSContextProvider } from '../context/ws-context';
import useAuth from '../hooks/use-auth';

const Home = () => {
  useAuth();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? (
    <WSContextProvider>
      <Dashboard />
    </WSContextProvider>
  ) : (
    <Splash />
  );
};

export default Home;
