import { authActions } from './auth-slice';

export const saveAuthData = (authData) => {
  return (dispatch) => {
    localStorage.setItem('auth', JSON.stringify(authData));
    dispatch(authActions.initialize(authData));
  };
};

export const removeAuthData = () => {
  return (dispatch) => {
    localStorage.removeItem('auth');
    dispatch(authActions.reset());
  };
};

export const updateAuthData = (successData) => {
  return (dispatch) => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    authData.profile = successData;
    localStorage.setItem('auth', JSON.stringify(authData));
    dispatch(authActions.update(authData));
  };
};
