import avatar from '../../images/avatar.png';
import { HTTP_BASE_DOMAIN } from '../../lib/api';
import { isValidHttpUrl } from '../../utils';

const Avatar = ({ image, className, onClick }) => {
  const src = image
    ? isValidHttpUrl(image)
      ? image
      : HTTP_BASE_DOMAIN + image
    : avatar;
  return (
    <div
      className={`border border-secondary rounded-circle ${className}`}
      style={{
        height: '3rem',
        width: '3rem',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img src={src} className='img-fluid' style={{ objectFit: 'cover' }} />
    </div>
  );
};

export default Avatar;
