import axios from 'axios';
import { HTTP_BASE_URL } from '../lib/api';

const instance = axios.create({
  baseURL: HTTP_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (config.token) {
      config.headers['Authorization'] = `Bearer ${config.token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
