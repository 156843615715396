import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useHttp from '../../../hooks/use-http';
import { USER_SEARCH_URL } from '../../../lib/api';
import Icon from '../../UI/Icon';
import Spinner from '../../UI/Spinner';

const Search = ({ newChat = false, onNewChatSearchComplete, onSearch }) => {
  const token = useSelector((state) => state.auth.userData.access);

  const [searchValue, setSearchValue] = useState('');
  const { sendRequest: sendSearchUserRequest, isLoading } = useHttp();

  useEffect(() => {
    if (searchValue) {
      const timeout = setTimeout(() => {
        if (newChat) {
          sendSearchUserRequest(
            {
              url: USER_SEARCH_URL,
              method: 'GET',
              params: { username: searchValue },
              token,
            },
            onNewChatSearchComplete
          );
        } else {
          onSearch(searchValue);
        }
      }, 700);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      // Empty search results to clear previous results
      if (!newChat) {
        onSearch(null);
      } else {
        onNewChatSearchComplete([], true);
      }
    }
  }, [searchValue]);

  return (
    <div className='d-flex bg-white align-items-center shadow px-3 py-1'>
      {!isLoading ? <Icon name='search' /> : <Spinner />}
      <input
        type='text'
        className='form-control border-0 bg-white'
        placeholder={newChat ? 'Search by username' : 'Search'}
        autoFocus
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  );
};

export default Search;
