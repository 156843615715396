import logo from '../images/logo.png';
import Spinner from '../components/UI/Spinner';

const Splash = ({ loader = false }) => {
  return (
    <div
      className='d-flex flex-column justify-content-center align-items-center'
      style={loader ? { height: '100%' } : { minHeight: '100vh' }}
    >
      {!loader && <img src={logo} alt='Chat App' height={150} width={200} />}
      <Spinner />
    </div>
  );
};

export default Splash;
