import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/UI/Spinner';
import Auth from '../../components/auth/Auth';
import useHttp from '../../hooks/use-http';
import useForm from '../../hooks/use-form';
import { saveAuthData } from '../../store/auth/auth-actions';
import { USER_CREATE_URL } from '../../lib/api';
import { titleCase } from '../../utils';

const initialFormData = {
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  name: '',
};

const SignUp = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { sendRequest: sendSignUpRequest } = useHttp();
  const {
    STATUS,
    status,
    setStatus,
    formData,
    touched,
    saveError,
    setSaveError,
    inputChangeHandler,
    inputBlurHandler,
  } = useForm(initialFormData);

  // Form Validations
  const getErrors = (data) => {
    const errors = {};

    // Check for backend validation errors
    if (saveError) {
      Object.keys(saveError).map((field) => {
        errors[field] = saveError[field][0];
      });
      return errors;
    }

    // Client side validations
    if (!data.username) errors.username = 'Username is required.';
    if (!data.email) errors.email = 'Email is required.';
    if (!data.name) errors.name = 'Name is required.';

    // Validator : Password
    const validatePassword = (password) => {
      if (!password) return 'Password is required';
      if (password.length < 8) return 'Atleast 8 characters required!';
      return null;
    };

    // Validator : Confirm Password
    const validateConfirmPassword = (password, confirmPassword) => {
      if (!confirmPassword || password !== confirmPassword)
        return "Passwords don't match";
      return null;
    };

    const passwordValidity = validatePassword(data.password);
    if (passwordValidity) errors.password = passwordValidity;

    const confirmPasswordValidity = validateConfirmPassword(
      data.password,
      data.confirmPassword
    );
    if (confirmPasswordValidity)
      errors.confirmPassword = confirmPasswordValidity;

    return errors;
  };

  // Derived state
  const errors = getErrors(formData);
  const isValid = Object.keys(errors).length === 0;

  const signUpHandler = (e) => {
    e.preventDefault();
    if (isValid) {
      setStatus(STATUS.SUBMITTING);

      // Transforming request body
      const reqBody = {
        ...formData,
        username: formData.username.toLowerCase(),
        name: titleCase(formData.name),
      };
      delete reqBody.confirmPassword;

      sendSignUpRequest(
        {
          url: USER_CREATE_URL,
          method: 'POST',
          body: reqBody,
        },
        signUpSuccess,
        signUpFailed
      );
    } else {
      toast.error('Invalid Form!');
    }
  };

  const signUpFailed = (errorData) => {
    setStatus(STATUS.SUBMITTED);
    if (errorData) {
      setSaveError(errorData);
    }
  };

  const signUpSuccess = (data) => {
    setStatus(STATUS.COMPLETED);
    toast.success('Account created successfully!');
    // Auto login the user.
    dispatch(saveAuthData(data));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Clearing backend validation error state,
    // once user starts refilling the form
    if (saveError) {
      setSaveError(null);
    }
  }, [formData]);

  return (
    <Auth isSignIn={false} onSubmit={signUpHandler}>
      <div className='mb-3'>
        <label htmlFor='name' className='form-label'>
          Full Name
        </label>
        <input
          type='text'
          className={`form-control ${
            (touched.name || status === STATUS.SUBMITTED) &&
            errors.name &&
            'is-invalid'
          }`}
          id='name'
          placeholder='chat.name'
          value={formData.name}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
        {(touched.name || status === STATUS.SUBMITTED) && (
          <div className='invalid-feedback'>{errors.name}</div>
        )}
      </div>
      <div className='mb-3'>
        <label htmlFor='username' className='form-label'>
          Username
        </label>
        <input
          type='text'
          className={`form-control ${
            (touched.username || status === STATUS.SUBMITTED) &&
            errors.username &&
            'is-invalid'
          }`}
          id='username'
          placeholder='chat.user'
          value={formData.username}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
        {(touched.username || status === STATUS.SUBMITTED) && (
          <div className='invalid-feedback'>{errors.username}</div>
        )}
      </div>
      <div className='mb-3'>
        <label htmlFor='email' className='form-label'>
          Email
        </label>
        <input
          type='email'
          className={`form-control ${
            (touched.email || status === STATUS.SUBMITTED) &&
            errors.email &&
            'is-invalid'
          }`}
          id='email'
          placeholder='chat.email'
          value={formData.email}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
        {(touched.email || status === STATUS.SUBMITTED) && (
          <div className='invalid-feedback'>{errors.email}</div>
        )}
      </div>
      <div className='mb-3'>
        <label htmlFor='password' className='form-label'>
          Password
        </label>
        <input
          type='password'
          className={`form-control ${
            (touched.password || status === STATUS.SUBMITTED) &&
            errors.password &&
            'is-invalid'
          }`}
          id='password'
          placeholder='********'
          value={formData.password}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
        {(touched.password || status === STATUS.SUBMITTED) && (
          <div className='invalid-feedback'>{errors.password}</div>
        )}
      </div>
      <div className='mb-3'>
        <label htmlFor='confirmPassword' className='form-label'>
          Confirm Password
        </label>
        <input
          type='password'
          className={`form-control ${
            (touched.confirmPassword || status === STATUS.SUBMITTED) &&
            errors.confirmPassword &&
            'is-invalid'
          }`}
          id='confirmPassword'
          placeholder='********'
          value={formData.confirmPassword}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
        {(touched.confirmPassword || status === STATUS.SUBMITTED) && (
          <div className='invalid-feedback'>{errors.confirmPassword}</div>
        )}
      </div>
      <div className='d-grid'>
        <button
          type='submit'
          disabled={!isValid || status === STATUS.SUBMITTING}
          className='btn btn-primary'
        >
          {status === STATUS.SUBMITTING ? (
            <Spinner color='secondary' />
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </Auth>
  );
};

export default SignUp;
