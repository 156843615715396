export const WS_BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? 'ws://127.0.0.1:8000/ws/'
    : process.env.REACT_APP_WS_BASE_URL;

export const HTTP_BASE_DOMAIN =
  process.env.NODE_ENV !== 'production'
    ? 'http://127.0.0.1:8000'
    : process.env.REACT_APP_HTTP_BASE_DOMAIN;
export const HTTP_BASE_URL = HTTP_BASE_DOMAIN + '/api/';
export const USER_CREATE_URL = 'user/create/';
export const USER_LOGIN_URL = 'user/token/';
export const USER_VERIFY_TOKEN_URL = 'user/verify/';
export const USER_SEARCH_URL = 'user/search/';
export const USER_PROFILE_URL = 'user/profile/';

export const CHAT_CREATE_URL = 'chat/';
export const CHAT_LIST_URL = CHAT_CREATE_URL;
export const CHAT_DETAIL_URL = CHAT_CREATE_URL;
