import { useDispatch } from 'react-redux';
import { chatActions } from '../../../store/chat/chat-slice';
import Icon from '../../UI/Icon';

const AsideWelcome = () => {
  const dispatch = useDispatch();

  return (
    <div className='d-flex align-items-center justify-content-center h-100 px-2 px-md-1'>
      <div className='text-center'>
        <span>Start chatting by searching for a user!</span>
      </div>
      <Icon
        name='circle-plus'
        className='fa-3x text-mix'
        style={{ position: 'absolute', bottom: 20 }}
        onClick={() => dispatch(chatActions.toggleNewChatActive())}
      />
    </div>
  );
};

export default AsideWelcome;
