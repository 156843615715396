import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  threads: [],
  selectedThread: {},
  selectedThreadChatHistory: {},
  isNewChatActive: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    initThreads(state, action) {
      state.threads = action.payload;
    },
    addNewThread(state, action) {
      state.threads.unshift(action.payload);
    },
    initChatHistory(state, action) {
      state.selectedThreadChatHistory = action.payload;
    },
    updateChatHistoryAndThread(state, action) {
      let todaysMessages = state.selectedThreadChatHistory['Today'];
      if (todaysMessages) {
        todaysMessages.push(action.payload);
      } else {
        state.selectedThreadChatHistory['Today'] = [action.payload];
      }
      const updatedThread = state.threads.find(
        (item) => item.id === action.payload.threadId
      );
      updatedThread.lastMessage = action.payload.message;
      updatedThread.updatedAt = action.payload.createdAt;
    },
    updateChatMessageStatus(state, action) {
      // let updatedItem = null;
      // // Fallback to for...in due to performance reasons
      // let isFound;
      // for (let date in state.selectedThreadChatHistory) {
      //   isFound = false;
      //   for (let i = 0; i < state.selectedThreadChatHistory[date].length; i++) {
      //     if (isFound) break;
      //     if (
      //       state.selectedThreadChatHistory[date][i].id === action.payload.id
      //     ) {
      //       updatedItem = state.selectedThreadChatHistory[date][i];
      //       isFound = true;
      //     }
      //   }
      //   if (isFound) break;
      // }
      // if (updatedItem) updatedItem.status = action.payload.status;

      const messages = state.selectedThreadChatHistory['Today'];
      const updatedItem = messages[messages.length - 1];
      if (updatedItem) updatedItem.status = action.payload.status;
    },
    bulkUpdateChatMessageStatus(state) {
      Object.values(state.selectedThreadChatHistory).map((dateArr) =>
        dateArr.forEach((item) => {
          item.status = 'Seen';
        })
      );
    },
    updateUnreadCount(state, action) {
      const updatedItem = state.threads.find(
        (item) => item.id === action.payload.id
      );
      if (updatedItem) updatedItem.unreadCount = action.payload.count;
    },
    selectThread(state, action) {
      state.selectedThread = action.payload;
    },
    selectThreadFromGivenUserId(state, action) {
      const updatedItem = state.threads.find(
        (thread) => thread.person.id === action.payload
      );
      if (updatedItem) state.selectedThread = updatedItem;
    },
    unselectThread(state) {
      state.selectedThread = {};
      state.selectedThreadChatHistory = {};
    },
    addAndSelectThread(state, action) {
      state.threads.unshift(action.payload);
      state.selectedThread = action.payload;
    },
    updateThread(state, action) {
      // Increment unreadCount by 1 and update lastMessage
      let updatedThreadIndex = null;
      const updatedThread = state.threads.find((item, index) => {
        const retVal = item.id === action.payload.threadId;
        if (retVal) {
          updatedThreadIndex = index;
        }
        return retVal;
      });
      updatedThread.unreadCount += 1;
      updatedThread.lastMessage = action.payload.message;
      updatedThread.updatedAt = action.payload.createdAt;
      state.threads.unshift(state.threads.splice(updatedThreadIndex, 1)[0]);
    },
    updateLatestValue(state, action) {
      const updatedThread = state.threads.find(
        (item) => item.id === action.payload.threadId
      );
      if (updatedThread) {
        updatedThread.person = action.payload.person;
      }
      if (state.selectedThread.id) {
        state.selectedThread.person = action.payload.person;
      }
    },
    updateTypingStatus(state, action) {
      // Set isTyping to True
      const updatedThread = state.threads.find(
        (item) => item.id === action.payload.threadId
      );
      if (updatedThread) {
        updatedThread.person.profile.isTyping = true;
        if (
          state.selectedThread &&
          state.selectedThread.id === action.payload.threadId
        ) {
          state.selectedThread.person.profile.isTyping = true;
        }
      }
    },
    resetTypingStatus(state, action) {
      // Remove isTyping property
      const updatedThread = state.threads.find(
        (item) => item.id === action.payload.threadId
      );
      if (updatedThread) {
        delete updatedThread.person.profile.isTyping;
        if (
          state.selectedThread &&
          state.selectedThread.id === action.payload.threadId
        ) {
          delete state.selectedThread.person.profile.isTyping;
        }
      }
    },
    // deprecatedUpdateOnlineStatus(state, action) {
    //   // Update user profile (online & last_seen)
    //   const { threadId, ...updatedProfile } = action.payload;
    //   const updatedThread = state.threads.find((item) => item.id === threadId);
    //   if (updatedThread) {
    //     updatedThread.person.profile = updatedProfile;
    //     if (state.selectedThread.id) {
    //       state.selectedThread.person.profile = updatedProfile;
    //     }
    //   }
    // },
    updateOnlineStatus(state, action) {
      // Update user profile (isOnline & status) if the thread is selectedThread
      if (
        state.selectedThread.id &&
        state.selectedThread.person.username === action.payload.username
      ) {
        state.selectedThread.person.profile.isOnline = true;
        // Make messages status as delivered.
        Object.values(state.selectedThreadChatHistory).map((dateArr) =>
          dateArr.forEach((item) => {
            if (item.status === 'Sent') item.status = 'Delivered';
          })
        );
      }
    },
    updateOfflineStatus(state, action) {
      // Update user profile (isOnline & lastSeen) if the thread is selectedThread
      if (
        state.selectedThread.id &&
        state.selectedThread.person.username === action.payload.username
      ) {
        state.selectedThread.person.profile.isOnline = false;
        state.selectedThread.person.profile.lastSeen = action.payload.lastSeen;
      }
    },
    toggleNewChatActive(state) {
      state.isNewChatActive = !state.isNewChatActive;
    },
    resetChatData() {
      return initialState;
    },
  },
});

export const chatActions = chatSlice.actions;

export default chatSlice;
