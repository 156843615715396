import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../../hooks/use-window-dimensions';
import { chatActions } from '../../../../store/chat/chat-slice';
import { getReadableDateTime } from '../../../../utils';
import Icon from '../../../UI/Icon';

const ChatWindowHeader = () => {
  const selectedThread = useSelector((state) => state.chat.selectedThread);

  const dispatch = useDispatch();

  const { width } = useWindowDimensions();

  return (
    <>
      <div className='p-4 d-flex align-items-center justify-content-between'>
        <div>
          {width < 992 && (
            <Icon
              name='arrow-left fa-1x'
              className='me-3 fs-4'
              onClick={() => {
                dispatch(chatActions.unselectThread());
              }}
            />
          )}
          <h2 className='d-inline text-capitalize'>
            {selectedThread.person.name}
          </h2>
          <span className='text-grey ms-1'>
            {selectedThread.person.profile.isTyping
              ? 'typing...'
              : selectedThread.person.profile.isOnline
              ? 'online'
              : `last seen ${getReadableDateTime(
                  selectedThread.person.profile.lastSeen
                )}`}
          </span>
        </div>
        {/* <Icon name='ellipsis-v' onClick={() => {}} /> */}
      </div>
      <div className='flex-grow-1'></div>
    </>
  );
};

export default ChatWindowHeader;
