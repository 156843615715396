import { useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WS_BASE_URL } from '../lib/api';
import { removeAuthData } from '../store/auth/auth-actions';
import { chatActions } from '../store/chat/chat-slice';
import { isObjectEmpty } from '../utils';

const WSContext = createContext();

export const WSContextProvider = (props) => {
  const [WSConn, setWSConn] = useState(null);
  const token = useSelector((state) => state.auth.userData.access);
  const threads = useSelector((state) => state.chat.threads);
  const selectedThread = useSelector((state) => state.chat.selectedThread);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendMessage = (msgObj) => {
    if (WSConn) {
      const data = JSON.stringify(msgObj);
      WSConn.send(data);
    }
  };

  useEffect(() => {
    if (!WSConn) {
      const WS = new WebSocket(`${WS_BASE_URL}chat/?token=${token}`);
      WS.onopen = () => {
        // console.log('WS open');
        setWSConn(WS);
      };
      WS.onclose = (event) => {
        // If 4001, unauthorized.
        if (event.code === 4001) {
          const errorMessage = 'Session Expired! Please Login.';
          toast.error(errorMessage);
          dispatch(removeAuthData());
          navigate('/sign-in');
        }
      };
      WS.onerror = (event) => {
        console.error(event);
        // console.log('WS error');
        toast.error('Something went wrong!');
      };
    } else {
      return () => {
        WSConn.close();
        setWSConn(null);
      };
    }
  }, [WSConn, token]);

  useEffect(() => {
    if (WSConn) {
      WSConn.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // console.log('NEWW', data);
        const { payload } = data;
        if (data.type === 'selfMessage') {
          dispatch(chatActions.updateChatHistoryAndThread(payload));
        } else if (data.type === 'targetMessage') {
          if (!isObjectEmpty(selectedThread)) {
            if (payload.threadId === selectedThread.id) {
              dispatch(chatActions.updateChatHistoryAndThread(payload));
              // Send ws_event for message seen
              sendMessage({
                type: 'status',
                status: 'Seen',
                messageId: payload.id,
                targetUserId: selectedThread.person.id,
              });
            } else {
              // Update unread_count, last_message & send ws_event for message delivered
              dispatch(chatActions.updateThread(payload));
              // console.log('PAYLOAD', payload);
              sendMessage({
                type: 'status',
                status: 'Delivered',
                messageId: payload.id,
                targetUserId: payload.user.id,
              });
            }
          } else {
            // Add new thread if not exists, else update thread & send ws_event for message delivered
            const existingThread = threads.find(
              (item) => item.id === payload.threadId
            );
            if (existingThread) dispatch(chatActions.updateThread(payload));
            else {
              const newThread = {
                id: payload.threadId,
                lastMessage: payload.message,
                unreadCount: 1,
                updatedAt: payload.createdAt,
                person: payload.user,
              };
              dispatch(chatActions.addNewThread(newThread));
            }
            sendMessage({
              type: 'status',
              status: 'Delivered',
              messageId: payload.id,
              targetUserId: payload.user.id,
            });
          }
        } else if (data.type === 'targetStatus') {
          if (payload.threadId === selectedThread?.id) {
            dispatch(chatActions.updateChatMessageStatus(payload));
          }
        } else if (data.type === 'targetStatusAll') {
          if (payload.id === selectedThread?.id) {
            dispatch(chatActions.bulkUpdateChatMessageStatus(payload));
          }
        } else if (data.type === 'selfStatusAll') {
          dispatch(chatActions.updateUnreadCount({ ...payload, count: 0 }));
        } else if (data.type === 'targetTyping') {
          dispatch(chatActions.updateTypingStatus(payload));
          setTimeout(() => {
            dispatch(chatActions.resetTypingStatus(payload));
          }, 2000);
        }
        // Deprecated onlineCheck
        // else if (data.type === 'selfOnlineCheck') {
        //   dispatch(chatActions.deprecatedUpdateOnlineStatus(payload));
        // }
        else if (data.type === 'targetOnline') {
          dispatch(chatActions.updateOnlineStatus(payload));
        } else if (data.type === 'targetOffline') {
          dispatch(chatActions.updateOfflineStatus(payload));
        }
      };
    }
  }, [WSConn, selectedThread, threads]);

  return (
    <WSContext.Provider value={{ sendMessage }}>
      {props.children}
    </WSContext.Provider>
  );
};

export default WSContext;
