import ChatList from '../chats/ChatList';
import Search from '../Search';
import NewChatHeader from './NewChatHeader';
import { useState } from 'react';
import { toast } from 'react-toastify';

const NewChat = () => {
  const [searchResults, setSearchResults] = useState([]);

  const addSearchResults = (results, clearResults = false) => {
    toast.dismiss();
    if (!clearResults && !results.length) {
      toast.error('No results found!');
    }
    setSearchResults(results);
  };

  return (
    <>
      <NewChatHeader />
      <Search newChat onNewChatSearchComplete={addSearchResults} />
      <ChatList newChatPage chats={searchResults} />
    </>
  );
};

export default NewChat;
