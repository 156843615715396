import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { USER_VERIFY_TOKEN_URL } from '../lib/api';
import { authActions } from '../store/auth/auth-slice';
import useHttp from './use-http';

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sendRequest: sendVerifyTokenRequest } = useHttp();

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));

    const tokenVerified = () => {
      dispatch(authActions.initialize(authData));
    };

    if (authData) {
      const token = authData.access;
      if (token) {
        sendVerifyTokenRequest(
          {
            url: USER_VERIFY_TOKEN_URL,
            method: 'POST',
            body: { token },
          },
          tokenVerified
        );
      }
    } else {
      navigate('/sign-in');
    }
  }, []);
};

export default useAuth;
