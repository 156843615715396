import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/UI/Spinner';
import Auth from '../../components/auth/Auth';
import useHttp from '../../hooks/use-http';
import useForm from '../../hooks/use-form';
import { saveAuthData } from '../../store/auth/auth-actions';
import { USER_LOGIN_URL } from '../../lib/api';

const initialFormData = {
  username: '',
  password: '',
};

const SignIn = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { sendRequest: sendSignInRequest } = useHttp();
  const {
    STATUS,
    status,
    setStatus,
    formData,
    touched,
    saveError,
    setSaveError,
    inputChangeHandler,
    inputBlurHandler,
  } = useForm(initialFormData);

  const getErrors = (data) => {
    const errors = {};

    // Check for backend validation errors
    if (saveError) {
      Object.keys(saveError).map((field) => {
        errors[field] = saveError[field][0];
      });
      return errors;
    }

    // Client side validations
    if (!data.username) errors.username = 'Username is required.';
    if (!data.password) errors.password = 'Password is required.';

    return errors;
  };

  // Derived state
  const errors = getErrors(formData);
  const isValid = Object.keys(errors).length === 0;

  const signInHandler = (e) => {
    e.preventDefault();
    if (isValid) {
      setStatus(STATUS.SUBMITTING);

      // Transforming request body
      const reqBody = {
        ...formData,
        username: formData.username.toLowerCase(),
      };

      sendSignInRequest(
        {
          url: USER_LOGIN_URL,
          method: 'POST',
          body: reqBody,
        },
        signInSuccess,
        signInFailed
      );
    } else {
      toast.error('Invalid Form!');
    }
  };

  const signInFailed = (errorData) => {
    setStatus(STATUS.SUBMITTED);
    if (errorData) {
      setSaveError(errorData);
    }
  };

  const signInSuccess = (data) => {
    setStatus(STATUS.COMPLETED);
    toast.success('Welcome!');
    // Auto login the user.
    dispatch(saveAuthData(data));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Clearing backend validation error state,
    // once user starts refilling the form
    if (saveError) {
      setSaveError(null);
    }
  }, [formData]);

  return (
    <Auth onSubmit={signInHandler}>
      <div className='mb-3'>
        <label htmlFor='username' className='form-label'>
          Username
        </label>
        <input
          type='text'
          className={`form-control ${
            (touched.username || status === STATUS.SUBMITTED) &&
            errors.username &&
            'is-invalid'
          }`}
          id='username'
          placeholder='chat.user'
          value={formData.username}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='password' className='form-label'>
          Password
        </label>
        <input
          type='password'
          className={`form-control ${
            (touched.password || status === STATUS.SUBMITTED) &&
            errors.password &&
            'is-invalid'
          }`}
          id='password'
          placeholder='********'
          value={formData.password}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
        />
      </div>
      <div className='d-grid'>
        <button
          type='submit'
          disabled={!isValid || status === STATUS.SUBMITTING}
          className='btn btn-primary'
        >
          {status === STATUS.SUBMITTING ? (
            <Spinner color='secondary' />
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </Auth>
  );
};

export default SignIn;
