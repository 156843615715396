import { useDispatch, useSelector } from 'react-redux';
import {
  removeAuthData,
  updateAuthData,
} from '../../../store/auth/auth-actions';
import { chatActions } from '../../../store/chat/chat-slice';
import Avatar from '../../UI/Avatar';
import Icon from '../../UI/Icon';
import { USER_PROFILE_URL } from '../../../lib/api';
import Spinner from '../../UI/Spinner';
import useHttp from '../../../hooks/use-http';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.userData.access);
  const userImage = useSelector((state) => state.auth.userData.profile.image);

  const { sendRequest: sendUploadImageRequest, isLoading } = useHttp();

  const logoutHandler = () => {
    dispatch(removeAuthData());
    dispatch(chatActions.resetChatData());
    navigate('/sign-in');
  };

  const onImageUploadSuccess = (successData) => {
    dispatch(updateAuthData(successData));
  };

  const imageChangeHandler = (e) => {
    if (e.target.files.length) {
      // Validate it the file size is >2MB
      if (e.target.files[0].size > 2097152) {
        toast.error('Image should be less than 2MB');
        return;
      }

      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      sendUploadImageRequest(
        {
          url: USER_PROFILE_URL,
          method: 'PATCH',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          token,
        },
        onImageUploadSuccess
      );
    }
  };

  return (
    <div className='d-flex justify-content-between align-items-center px-3 py-2 shadow-lg'>
      <label htmlFor='upload-button'>
        {isLoading ? <Spinner /> : <Avatar image={userImage} />}
      </label>
      <input
        type='file'
        accept='image/png, image/jpeg'
        id='upload-button'
        style={{ display: 'none' }}
        onChange={imageChangeHandler}
      />
      <div>
        <Icon
          name='comment-alt'
          className='me-3'
          onClick={() => dispatch(chatActions.toggleNewChatActive())}
        />
        {/* <Icon name='ellipsis-v' onClick={() => {}} /> */}
        <Icon name='arrow-right-from-bracket' onClick={logoutHandler} />
      </div>
    </div>
  );
};

export default Setting;
