import { useState } from 'react';

const STATUS = {
  IDLE: 'IDLE',
  SUBMITTED: 'SUBMITTED',
  SUBMITTING: 'SUBMITTING',
  COMPLETED: 'COMPLETED',
};

const useForm = (initialFormData) => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [formData, setFormData] = useState(initialFormData);
  const [touched, setTouched] = useState({});
  const [saveError, setSaveError] = useState(null);

  const inputChangeHandler = (e) => {
    setFormData((currFormData) => {
      return {
        ...currFormData,
        [e.target.id]: e.target.value,
      };
    });
  };

  const inputBlurHandler = (e) => {
    setTouched((currTouchedObj) => {
      return { ...currTouchedObj, [e.target.id]: true };
    });
  };

  return {
    STATUS,
    status,
    setStatus,
    formData,
    setFormData,
    touched,
    setTouched,
    saveError,
    setSaveError,
    inputChangeHandler,
    inputBlurHandler,
  };
};

export default useForm;
