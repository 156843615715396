import { useRef, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { getReadableTime } from '../../../../utils';
import Icon from '../../../UI/Icon';
import classes from './ChatWindowMessageBox.module.scss';

const ChatWindowMessageBox = () => {
  const messagesEndRef = useRef(null);
  const chatHistory = useSelector(
    (state) => state.chat.selectedThreadChatHistory
  );
  const userId = useSelector((state) => state.auth.userData.id);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  return (
    <div className={`px-4 pb-3`} style={{ overflowY: 'scroll' }}>
      {Object.keys(chatHistory).map((date) => {
        return (
          <div className='text-center' key={date}>
            <div
              className={`${classes.fancy} d-inline-block my-3 py-2 px-4 bg-light rounded-pill`}
            >
              <small className='position-relative text-grey'>{date}</small>
            </div>
            {chatHistory[date].map((item) => {
              const isSentByMe = item.user.id === userId;
              return (
                <div
                  key={item.id}
                  className={`py-2 ${isSentByMe ? 'text-end' : 'text-start'}`}
                >
                  <div
                    className={`d-inline-block position-relative bg-secondary rounded-top rounded-right ps-3 pe-2 pb-2 pt-2`}
                    style={{
                      maxWidth: '60%',
                      wordBreak: 'break-word',
                    }}
                  >
                    <div
                      className={`${classes['chat-item']} ${
                        isSentByMe
                          ? classes['chat-item-right']
                          : classes['chat-item-left']
                      } border-secondary`}
                    >
                      <span className='me-2' style={{ maxWidth: '30%' }}>
                        {item.message}
                      </span>
                      <sub>
                        <small>
                          {getReadableTime(item.createdAt)}
                          {isSentByMe && (
                            <span className='ms-1'>
                              <Icon
                                name={
                                  (item.status === 'Seen') |
                                  (item.status === 'Delivered')
                                    ? 'check-double'
                                    : 'check'
                                }
                                className={
                                  item.status === 'Seen'
                                    ? 'text-mix'
                                    : 'text-primary'
                                }
                              />
                            </span>
                          )}
                        </small>
                      </sub>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default memo(ChatWindowMessageBox);
