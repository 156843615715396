import { useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';
import Aside from '../components/dashboard/aside/Aside';
import ChatWindow from '../components/dashboard/main/ChatWindow/ChatWindow';
// import ChatWindow from '../components/dashboard/main/ChatWindow';
import Welcome from '../components/dashboard/main/Welcome';
import useWindowDimensions from '../hooks/use-window-dimensions';

const Dashboard = () => {
  const selectedThread = useSelector((state) => state.chat.selectedThread);
  const { width } = useWindowDimensions();

  let innerContent = null;
  let className = 'd-flex';
  if (width < 992) {
    if (selectedThread.id) {
      innerContent = <ChatWindow />;
    } else {
      innerContent = <Aside />;
      className = '';
    }
  } else {
    innerContent = (
      <>
        <Aside />
        {selectedThread.id ? <ChatWindow /> : <Welcome />}
      </>
    );
  }

  const content = (
    <Div100vh className={`${className} overflow-hidden`}>
      {innerContent}
    </Div100vh>
  );

  return content;
};

export default Dashboard;
