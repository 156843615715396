import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// import WSContext from '../../../../context/ws-context';
import useHttp from '../../../../hooks/use-http';
import { CHAT_DETAIL_URL } from '../../../../lib/api';
import Splash from '../../../../pages/Splash';
import { chatActions } from '../../../../store/chat/chat-slice';
import { getReadableDateTime } from '../../../../utils';
import ChatWindowHeader from './ChatWindowHeader';
import ChatWindowInput from './ChatWindowInput';
import ChatWindowMessageBox from './ChatWindowMessageBox';

const ChatWindow = () => {
  const { sendRequest: fetchChatMessages, isLoading } = useHttp(true);

  const token = useSelector((state) => state.auth.userData.access);
  const selectedThread = useSelector((state) => state.chat.selectedThread);

  // const { sendMessage } = useContext(WSContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedThread.id) {
      // DEPRECATED
      // const timeout = setInterval(() => {
      //   sendMessage({
      //     type: 'online-check',
      //     threadId: selectedThread.id,
      //     targetUserId: selectedThread.person.id,
      //   });
      // }, 5000);
      fetchChatMessages(
        {
          url: `${CHAT_DETAIL_URL}${selectedThread.id}`,
          token,
        },
        (successData) => {
          // Transform by datetime
          const transformedData = {};
          successData.messages.map((message) => {
            const date = getReadableDateTime(message.createdAt, false);
            transformedData[date]
              ? transformedData[date].push(message)
              : (transformedData[date] = [message]);
          });
          dispatch(chatActions.initChatHistory(transformedData));

          // Update latest values of person
          // (in both threads list and selectedThred)
          dispatch(
            chatActions.updateLatestValue({
              threadId: selectedThread.id,
              person: successData.person,
            })
          );
        },
        (errorData) => {
          console.error(errorData);
        }
      );

      // return () => {
      //   clearInterval(timeout);
      // };
    }
  }, [selectedThread.id]);

  return (
    <div className='d-flex justify-content-between flex-column bg-white w-100'>
      <ChatWindowHeader />
      {!isLoading && <ChatWindowMessageBox />}
      {isLoading && <Splash loader />}
      <ChatWindowInput />
    </div>
  );
};

export default ChatWindow;
