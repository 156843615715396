import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { chatActions } from '../../../../store/chat/chat-slice';
import Icon from '../../../UI/Icon';

const NewChatHeader = () => {
  const dispatch = useDispatch();

  return (
    <div className='bg-mix p-3 d-flex align-items-center'>
      <Icon
        name='angle-left'
        className='me-3'
        onClick={() => dispatch(chatActions.toggleNewChatActive())}
      />
      <strong>New chat</strong>
    </div>
  );
};

export default memo(NewChatHeader);
