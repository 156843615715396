import classes from './Auth.module.css';
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

const Auth = ({
  isSignIn = true,
  onSubmit,
  errorBlock = null,
  children: formFields,
}) => {
  return (
    <div className={`${classes.auth}`}>
      <div className='text-center'>
        <img src={logo} alt='Chat App' height={150} width={200} />
        <span className='d-block'>Simple. Secure.</span>
      </div>
      <div
        className={`${classes['auth-content']} border border-overlay rounded shadow-lg`}
      >
        <h1 className='text-center mb-5 mt-3'>
          {isSignIn ? 'Sign In' : 'Sign Up'}
        </h1>
        {errorBlock}
        <form onSubmit={onSubmit}>
          {formFields}
          <div className='text-center mt-3'>
            {isSignIn && (
              <span>
                Don't have an account ? <br />
                <Link to='/sign-up' className='nav-link'>
                  Sign Up
                </Link>
              </span>
            )}
            {!isSignIn && (
              <span>
                Already have an account ? <br />
                <Link to='/sign-in' className='nav-link'>
                  Sign In
                </Link>
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Auth;
