import Avatar from '../../../UI/Avatar';
import { getReadableDateTime } from '../../../../utils';

const ChatItem = ({
  name,
  image,
  lastMessage,
  updatedAt: lastMessageTime,
  unreadCount,
  shouldHighlight,
  isTyping,
  onClick,
}) => {
  return (
    <div
      className={`row g-0 justify-content-between px-2 py-3 border-bottom ${
        shouldHighlight ? 'bg-secondary' : ''
      }`}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className='col-9 d-flex'>
        <div>
          <Avatar image={image} />
        </div>
        <div className='truncate ms-2 align-self-center'>
          <span>{name}</span>
          {(lastMessage || isTyping) && (
            <div className='truncate'>
              <span
                className={`m-0 ${isTyping ? 'text-primary' : 'text-grey'}`}
              >
                {isTyping ? 'typing...' : lastMessage}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='col-3 text-end align-self-center'>
        {lastMessageTime && (
          <span className='d-block text-mix'>
            {getReadableDateTime(lastMessageTime)}
          </span>
        )}
        {unreadCount?.toString() && unreadCount !== 0 && (
          <span className='badge bg-mix rounded-pill'>{unreadCount}</span>
        )}
      </div>
    </div>
  );
};

export default ChatItem;
