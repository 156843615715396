import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../axios';
import { removeAuthData } from '../store/auth/auth-actions';

const useHttp = (initialLoading = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(initialLoading);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async (requestConfig, applyData, applyError = () => {}) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios({
          url: requestConfig.url,
          method: requestConfig.method ?? 'GET',
          headers: requestConfig.headers ?? {},
          data: requestConfig.body ?? null,
          params: requestConfig.params ?? null,
          token: requestConfig.token ?? null,
        });
        setIsLoading(false);
        applyData(response.data.data);
      } catch (err) {
        // console.log('ERR', err);
        setIsLoading(false);
        if (err.response && err.response.data) {
          let errorMessage = err.response.data.message;
          let errorData = err.response.data.data;
          const statusCode = err.response.data.code;
          if (statusCode === 400) {
            if (errorData['nonFieldErrors']) {
              errorMessage = errorData['nonFieldErrors'].join();
            } else {
              errorMessage = 'Form Validation Error!';
            }
          } else if (statusCode === 401) {
            if (errorMessage !== 'Invalid credentials') {
              errorMessage = 'Session Expired! Please Login.';
              toast.error(errorMessage);
              dispatch(removeAuthData());
              navigate('/sign-in');
              return;
            }
          }
          applyError(errorData);
          setError(errorMessage);
          toast.error(errorMessage);
        } else {
          toast.error(err.message);
          applyError();
        }
      } finally {
        // PROBLEM : Warning: Can't perform a React state update on an unmounted component.
        // This is a no-op, but it indicates a memory leak in your application.
        // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
        // TEMP. FIX : Adding state update manually to success and error cases.
        // setIsLoading(false);
      }
    },
    []
  );

  return { sendRequest, isLoading, setIsLoading, error };
};

export default useHttp;
