// import dp from '../../../../images/dp.JPG';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WSContext from '../../../../context/ws-context';
import useHttp from '../../../../hooks/use-http';
import { CHAT_CREATE_URL } from '../../../../lib/api';
import { chatActions } from '../../../../store/chat/chat-slice';
import { isObjectEmpty } from '../../../../utils';
import ChatItem from './ChatItem';

// const sampleChats = [
// {
//   name: 'John Doe',
//   image: dp,
//   lastMessage: 'I will be there!',
//   lastMessageTime: '13:45',
//   unreadCount: 2,
// },
// {
//   name: 'Jane Doe',
//   image: dp,
//   lastMessage: 'Hooray!',
//   lastMessageTime: '18:30',
//   unreadCount: 0,
// },
// {
//   name: 'Tim Cook',
//   image: dp,
//   lastMessage: 'Good bye...',
//   lastMessageTime: '12:00',
//   unreadCount: 4,
// },
// {
//   name: 'Jake Doe',
//   image: dp,
//   lastMessage: 'Good one!',
//   lastMessageTime: '01:10',
//   unreadCount: 1,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// {
//   name: 'Steve Jobs',
//   image: dp,
//   lastMessage: 'Sure, that"s ok lorem iosus dfas  fasd f fasd f',
//   lastMessageTime: '11:15',
//   unreadCount: 6,
// },
// ];

const ChatList = ({ chats }) => {
  const isNewChatActive = useSelector((state) => state.chat.isNewChatActive);
  const token = useSelector((state) => state.auth.userData.access);
  const selectedThread = useSelector((state) => state.chat.selectedThread);
  const dispatch = useDispatch();
  const { sendMessage } = useContext(WSContext);
  const { sendRequest: sendChatRequest } = useHttp();

  const chatItemClickedHandler = (chatObj) => {
    if (isNewChatActive) {
      sendChatRequest(
        {
          url: CHAT_CREATE_URL,
          method: 'POST',
          body: {
            secondPerson: chatObj.id,
          },
          token,
        },
        (successData) => {
          // Listen for status(online/offline) update of
          // target user
          sendMessage({
            type: 'online-check',
            threadId: successData.id,
            targetUserId: successData.person.id,
          });
          dispatch(chatActions.addAndSelectThread(successData));
          dispatch(chatActions.toggleNewChatActive());
        },
        (errorData) => {
          console.error(errorData);
          if (
            errorData?.nonFieldErrors?.includes(
              'Personal Chat object already exists'
            )
          ) {
            dispatch(chatActions.selectThreadFromGivenUserId(chatObj.id));
            dispatch(chatActions.toggleNewChatActive());
            sendMessage({
              type: 'online-check',
              threadId: chatObj.id,
              targetUserId: chatObj.person.id,
            });
          }
        }
      );
    } else {
      dispatch(chatActions.selectThread(chatObj));
      sendMessage({
        type: 'online-check',
        threadId: chatObj.id,
        targetUserId: chatObj.person.id,
      });
      if (chatObj.unreadCount) {
        sendMessage({
          type: 'status-all',
          threadId: chatObj.id,
          targetUserId: chatObj.person.id,
        });
      }
    }
  };

  return (
    <div
      className={`chat-list d-flex flex-column`}
      style={chats.length ? { overflowY: 'auto' } : { height: '100%' }}
    >
      {chats.length !== 0 && (
        <div>
          {chats.map((chat) => (
            <ChatItem
              {...chat}
              key={chat.id}
              shouldHighlight={
                !isNewChatActive &&
                !isObjectEmpty(selectedThread) &&
                chat.id === selectedThread.id
              }
              name={isNewChatActive ? chat.name : chat.person.name}
              image={
                isNewChatActive ? chat.profile.image : chat.person.profile.image
              }
              isTyping={!isNewChatActive && chat.person.profile.isTyping}
              onClick={chatItemClickedHandler.bind(null, chat)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatList;
